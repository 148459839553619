export const state = () => ({
	loading: false,
	languages: [],
	// cancel token
	cancelToken: {}
})

export const actions = {
	async languages({ commit, state }) {
		commit('setLoading', true)
		commit('setCancelToken', { languages: this.$axios.CancelToken.source() })
		await this.$axios
			.get(`/website-setting/languages`, {
				cancelToken: state.cancelToken.languages.token
			})
			.then((response) => {
				commit('setLanguages', response.data)
				commit('setLoading', false)
			})
			.catch(() => false)
		commit('setLoading', false)
	},
	async createLanguage({ dispatch, rootGetters }, form) {
		await this.$axios
			.post('/website-setting/languages', form)
			.then(() => {
				this.$toast.success(rootGetters['master/storeTl']('languageCreatedSuccessful'))
				dispatch('languages', { is_active: true })
			})
			.catch(() => false)
	},
	async updateLanguage({ commit, dispatch, rootGetters }, data) {
		await this.$axios
			.put(`/website-setting/languages/${data.id}`, { is_active_bo: data.status })
			.then((_) => {
				dispatch('master/languages', true, { root: true })
				dispatch('languages', { is_active: true })
				this.$toast.success(rootGetters['master/storeTl']('languageSettingsUpdatedSuccessful'))
			})
			.catch(() => false)
	},
	async updateLandingLanguage({ rootGetters, dispatch }, object) {
		await this.$axios
			.put(`/website-setting/languages/landing/${object.currency}`, { ...object.body })
			.then((_) => {
				dispatch('settings/currency', { is_active: true }, { root: true })
				this.$toast.success(rootGetters['master/storeTl']('languageSettingsUpdatedSuccessful'))
			})
			.catch(() => false)
	},
	async updateDefaultCurrency({ dispatch }, currency) {
		const response = await this.$axios.put(`/website-setting/languages/landing/set-default-currency/${currency}`)
			.then((response) => {
				dispatch('settings/currency', { is_active: true }, { root: true })
				return true;
			})
			.catch(() => {
				return false;
			})
		return response;
	}
}

export const mutations = {
	setLoading: (state, v) => {
		state.loading = v
	},
	setLanguages: (state, v) => {
		state.languages = v
	},
	setCancelToken: (state, data) => {
		const oldData = { ...state.cancelToken }
		state.cancelToken = {
			...oldData, ...data
		}
	}
}

export const getters = {
	searchLanguages: (state) => (currencies, currency, search) => {
		if (!currency && !search) return state.languages

		let languages = state.languages
		if (search) {
			const filterCondition = (el) => {
				if (el.name.toLowerCase().includes(search.toLowerCase())) {
					return true
				}
				return false
			}
			languages = languages.filter(filterCondition)
		}
		if (currency) {
			const getSelectedCurrency = currencies.find(
				(el) => el.code === currency
			)

			if (getSelectedCurrency) {
				const getSupportedLanguage =
					getSelectedCurrency.supported_languages.map((data) => {
						return {
							language: data.language,
							is_active: data.is_active,
							is_default: data.is_default,
						}
					})

				languages = languages.filter(el => {
					if (getSupportedLanguage.some((sEl => sEl.language === el.code))) {
						return true;
					}
					return false
				})
				languages = languages.map((data) => {
					const form = { ...data }
					// check if the language is active or not
					const getLanguageStatusByCurrency = getSelectedCurrency.supported_languages.find((el => el.language === data.code))

					form.is_active_bo = getLanguageStatusByCurrency.is_active
					form.is_default = getLanguageStatusByCurrency.is_default
					return form
				})

			}
		}

		return languages
	},
}
