// State
export const state = () => ({
	loading: false,
	users: [],
	user: {},
	activities: {},
	activity: {},
	activityTypes: [],
	myProfile: {},
	tfa: {},

	userCancelToken: {}
})

// Actions
export const actions = {
	// Get all users
	async users({ commit, state }, params) {
		commit('setUserCancelToken', { users: this.$axios.CancelToken.source() })
		params.is_deleted = false;
		if (params && params.currency === 'All') delete params.currency;
		try {
			commit('loading', true)
			await this.$axios
				.get(`/users`, {
					params,
					cancelToken: state.userCancelToken.users.token
				})
				.then((response) => {
					commit('setUsers', response.data)
					commit('loading', false)
				})
		} catch (error) {
			commit('loading', false)
		}
	},

	// Get single user
	async user({ commit, state }, userId) {
		commit('setUserCancelToken', { user: this.$axios.CancelToken.source() })
		commit('loading', true)
		await this.$axios.get(`/users/${userId}`, {
			cancelToken: state.userCancelToken.user.token
		}).then(response => {
			commit('setUser', response.data)
			commit('loading', false)
		}).catch(() => false)
	},

	// Create user
	async create({ commit, rootGetters }, data) {
		commit('loading', true)
		await this.$axios.post(`/users`, data).then(() => {
			this.$toast.success(rootGetters['master/storeTl']('userCreatedSuccessful'))
			commit('loading', false)
			this.$router.push('/users/users')
		}).catch(() => {
			commit('loading', false)
		})
	},

	// Get all activity
	async getActivities({ commit, state }, data) {
		commit('setUserCancelToken', { getActivities: this.$axios.CancelToken.source() })
		const params = { ...data }
		if (params.activity === 'all') {
			delete params.activity
		}
		if (!params.ip) delete params.ip
		if (!params.user_name) delete params.user_name
		if (!params.user_email) delete params.user_email

		try {
			return await this.$axios
				.get(`/user-activity`, {
					params,
					cancelToken: state.userCancelToken.getActivities.token
				})
				.then((response) => {
					commit('setActivities', response.data)
					return true
				})
		} catch (error) {
			return false
		}
	},

	// Get activity types
	activityTypes({ commit, state }) {
		if (state.activityTypes?.length > 0) {
			commit('activityTypes', state.activityTypes)
		}
		commit('setUserCancelToken', { activityTypes: this.$axios.CancelToken.source() })
		commit('loading', true)
		this.$axios
			.get(`/user-activity/types`, {
				cancelToken: state.userCancelToken.activityTypes.token
			})
			.then((response) => {
				commit('activityTypes', response.data)
			}).finally(() => {
				commit('loading', false)
			})
	},

	// Get single activity
	async activity({ commit }, id) {
		commit('loading', true)
		await this.$axios
			.get(`/user-activity/${id}`)
			.then((response) => {
				commit('activity', response.data)
			}).finally(() => {
				commit('loading', false)
			})
	},

	// Get user profile
	async myProfile({ commit, state }) {
		try {
			commit('setUserCancelToken', { myProfile: this.$axios.CancelToken.source() })
			return await this.$axios
				.get(`users.info`, {
					cancelToken: state.userCancelToken.myProfile.token
				})
				.then((response) => {
					commit('setMyProfile', response.data)
					return true
				})
		} catch (error) {
			return false
		}
	},

	// Update current logged in profile.
	async update(context, data) {
		const newData = { ...data }
		const id = newData.id
		delete newData.id;
		try {
			const response = await this.$axios
				.put(`/users/${id}`, newData)
				.then((response) => {
					return true
				})
				.catch(() => {
					return false
				})
			return response;
		} catch (error) {
			return false
		}
	},
	// Update current logged in profile.
	async updateUser({ _ }, data) {
		try {
			return await this.$axios
				.put(`/users`, data)
				.then((response) => {
					return true
				})
		} catch (error) {
			return false
		}
	},

	async delete({ commit, rootGetters }, id) {
		try {
			commit("loading", true)
			return await this.$axios
				.delete(`/users/${id}`)
				.then((response) => {
					if (typeof response.data.message === 'string') {
						this.$toast.success(rootGetters['master/storeTl'](response.data.message))
					} else {
						this.$toast.success(response.data.message)
					}
					commit('loading', false)
					return true
				})
		} catch (error) {
			commit('loading', false)
			return false
		}
	},

	async setUpFinanceTFA({ commit }, data) {
		try {
			const response = await this.$axios
				.put(`/users/setup-finance-tfa`, data)
				.then((response) => {
					commit('setTfa', response.data)
					return true
				}).catch((_) => {
					return false
				})
			return response
		} catch (error) {
			return false
		}
	},
	async resetFinanceTFA({ commit }, id) {
		try {
			const response = await this.$axios
				.put(`/users/reset-finance-tfa/${id}`, {})
				.then((response) => {
					return true
				}).catch((_) => {
					return false
				})
			return response
		} catch (error) {
			return false
		}
	},
	async resetLoginTFA({ commit }, id) {
		try {
			const response = await this.$axios
				.put(`/users/reset-tfa/${id}`, {})
				.then((response) => {
					return true
				}).catch((_) => {
					return false
				})
			return response
		} catch (error) {
			return false
		}
	},

	async verifyTFA({ commit }, data) {
		try {
			const response = await this.$axios
				.post(`/users/verify-finance-tfa-otp`, data)
				.then((_) => {
					return true
				}).catch((_) => {
					return false
				})
			return response
		} catch (error) {
			return false
		}
	},


}

// Mutations
export const mutations = {
	loading(state, type) {
		state.loading = type
	},
	setUsers(state, data) {
		state.users = data
	},
	setUser(state, data) {
		state.user = data
	},
	setActivities(state, data) {
		state.activities = data
	},
	activity(state, v) {
		state.activity = v
	},
	activityTypes(state, v) {
		state.activityTypes = v
	},
	setMyProfile(state, data) {
		state.myProfile = data
	},
	setTfa(state, data) {
		state.tfa = data
	},
	setUserCancelToken: (state, data) => {
		const oldData = { ...state.userCancelToken }
		state.userCancelToken = {
			...oldData, ...data
		}
	}
}